import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import GoogleMap from "../sidebar/googlemap";
import Footer from "../layout/footer";
import { toast } from "react-toastify";

const subTitle = "CONTACTEZ-NOUS";
const title = "Nous sommes toujours impatients de vous entendre !";
const conSubTitle = "CONTACTEZ-NOUS";
const conTitle = "Remplissez le formulaire ci-dessous pour que nous puissions mieux vous connaître ainsi que vos besoins.";
const btnText = "Envoyez";


const contactList = [
    {
        imgUrl: 'assets/images/icon/01.png',
        imgAlt: 'contact icon',
        title: 'Adresse',
        desc: 'lac 1',
    },
    {
        imgUrl: 'assets/images/icon/02.png',
        imgAlt: 'contact icon',
        title: 'Numéro de téléphone',
        desc: '+216 20 472 727 / +216 21 646 503',
    },
    {
        imgUrl: 'assets/images/icon/03.png',
        imgAlt: 'contact icon',
        title: 'Email',
        desc: 'contact@arzaak.tn',
    },
    {
        imgUrl: 'assets/images/icon/04.png',
        imgAlt: 'contact icon',
        title: 'Notre site Web',
        desc: 'https://arzaak.tn/',
    },
]


const ContactPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        telephone: '',
        sujet: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, email, telephone, sujet, message } = formData;
        if (!name || !email || !telephone || !sujet|| !message) {
            toast.error('Veuillez remplir tous les champs du formulaire.');
            return;
          }
        try {
            const response = await axios.post('http://localhost:4000/contacts/addContact', formData);
            console.log('Contact saved:', response.data);
            toast.success("Votre demande de contact a été envoyée avec succès !");
            setFormData({
                name: '',
                email: '',
                telephone: '',
                sujet: '',
                message: ''
            });
        } catch (error) {
            console.error('There was an error saving the contact!', error);
        }
    };
    useEffect(() => {
        document.title = "Arzaak-Contact";
    },[]);
    return ( 
        <Fragment>
          
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
            <div className="map-address-section padding-tb section-bg">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle">{subTitle}</span>
                        <h2 className="title">{title}</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row flex-row-reverse">
                            <div className="col-xl-4 col-lg-5 col-12">
                                <div className="contact-wrapper">
                                    {contactList.map((val, i) => (
                                        <div className="contact-item" key={i}>
                                            <div className="contact-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="contact-content">
                                                <h6 className="title">{val.title}</h6>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-7 col-12">
                                <GoogleMap />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-section padding-tb">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle">{conSubTitle}</span>
                        <h2 className="title">{conTitle}</h2>
                    </div>
                    <div className="section-wrapper">
                    <form className="contact-form" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Nom & Prénom *"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Email *"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="telephone"
                                    value={formData.telephone}
                                    onChange={handleChange}
                                    placeholder="Numéro de téléphone *"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="sujet"
                                    value={formData.sujet}
                                    onChange={handleChange}
                                    placeholder="Sujet *"
                                    required
                                />
                            </div>
                            <div className="form-group w-100">
                                <textarea
                                    rows="8"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    placeholder="Message"
                                    required
                                ></textarea>
                            </div>
                            <div className="form-group w-100 text-center">
                                <button className="lab-btn" type="submit"><span>{btnText}</span></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}



export default ContactPage;